import React from 'react'
import avatar from "../../images/avatar.png";
import barcode from "../../images/barcode.svg";
import QrModal from '../../components/QrModal/QrModal';

export const Settings = () => {
  const [showQrModal, setShowQrModal] = React.useState(false);

  return (
    <React.Fragment>
      {showQrModal && (<QrModal setShowQrModal={setShowQrModal} />)}
      <div>
        <div className='px-4'>
          <div className='pt-4 absolute'>
            <a href='/' className='text-green cursor-pointer font-semibold'>Back</a>
          </div>
          <p className='font-semibold pt-4 text-base text-center'>Privacy Settings</p>

          <div className='bg-dark-gray rounded-[10px] px-4 py-3 flex items-center justify-between mt-8'>
            <a href='/chattingpage' className='flex gap-2 items-start'>
              <div className='relative min-w-9 max-w-9'>
                <img src={avatar} className='min-w-9 max-w-9 rounded-full' />
                <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
              </div>
              <div className='w-full'>
                <p className='text-sm text-white'>Alex</p>
                <p className='text-[10px] text-gray-400'>CEO</p>
              </div>
            </a>

            <a onClick={() => setShowQrModal(true)} className='cursor-pointer w-10 h-10 bg-icon flex justify-center items-center rounded-full'>
              <img src={barcode} className='w-[18px] h-[18px]' alt='Media' />
            </a>
          </div>

          <div>
            <h1 className='text-white text-base mb-3 mt-8'>Privacy settings</h1>
            <div className='flex justify-between items-center bg-dark-gray rounded-md p-3'>
              <div>
                <p className='text-sm text-white'>User ID Searchability</p>
                <p className='text-[10px] text-white'>If turned off users will not be able to search you.</p>
              </div>

              <div className="flex">
                <input type="checkbox" id="switch" />
                <label className="toggle" htmlFor="switch">
                  Toggle
                </label>
              </div>
            </div>
          </div>
        </div>


      </div>
    </React.Fragment>
  )
}
