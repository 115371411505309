import React, { useRef, useEffect, useState } from 'react';
import dots from "../../images/dots.png";
import plus from "../../images/plus.svg";
import mute from "../../images/mute.svg";
import moredots from "../../images/moredots.svg";
import avatar from "../../images/avatar.png";
import { SearchField } from '../../components/SearchField/SearchField';
import { ChatSlider } from '../../components/ChatSlider/ChatSlider';
import { ChatHeader } from '../../components/ChatHeader/ChatHeader';

export const ChatList = () => {
  const chatItemRef = useRef(null);
  const slideActionsRef = useRef(null);
  const [translateX, setTranslateX] = useState(0);

  let startX = 0;
  let currentX = 0;

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    currentX = e.touches[0].clientX;
    const moveX = startX - currentX;

    // Slide left to reveal the action
    if (moveX > 0 && moveX < 100) {
      setTranslateX(-moveX);
    }
  };

  const handleTouchEnd = () => {
    if (startX - currentX > 50) {
      // Fully reveal actions if swipe is far enough
      setTranslateX(-80);
    } else {
      // Reset if swipe wasn't far enough
      setTranslateX(0);
    }
  };

  useEffect(() => {
    const chatItemElement = chatItemRef.current;

    if (chatItemElement) {
      chatItemElement.addEventListener('touchstart', handleTouchStart);
      chatItemElement.addEventListener('touchmove', handleTouchMove);
      chatItemElement.addEventListener('touchend', handleTouchEnd);

      // Cleanup event listeners on component unmount
      return () => {
        chatItemElement.removeEventListener('touchstart', handleTouchStart);
        chatItemElement.removeEventListener('touchmove', handleTouchMove);
        chatItemElement.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, []); // Empty dependency array ensures this only runs on mount

  return (
    <React.Fragment>
      <main>
        <div className='main-wrapper'>
          <ChatHeader />

          <SearchField />
        </div>

        <ChatSlider />
      </main>
    </React.Fragment>
  );
};
