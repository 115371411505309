import React from 'react'
import avatar from "../../images/avatar.png";

export const Contacts = () => {

  return (
    <React.Fragment>
      <div className='main-wrapper'>
        <div className='pt-4 absolute'>
          <a href='/' className='text-green cursor-pointer font-semibold'>Cancel</a>
        </div>
        <p className='font-semibold pt-4 text-base text-center'>Contacts</p>
        <div className="group fields py-5">
          <div className="relative flex items-center">
            <input
              type="text"
              placeholder='Search name and number '
              className="relative h-8 text-xs w-full border border-gray-400 rounded-full bg-[#2C2C2E] pr-4 pl-9 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-[#2C2C2E] focus:ring-1 focus:ring-green focus:drop-shadow-lg"
            />
            <span className="absolute left-[10px] transition-all duration-200 ease-in-out group-focus-within:text-primary">
              <svg
                className='w-5'
                fill='white'
                viewBox="0 0 512 512"
              >
                <path d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3  c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2  c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2  c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z" />
              </svg>
            </span>
          </div>

        </div>

        <a href='/addfriend' className='flex gap-2 min-h-10 mb-4 p-3 rounded-[10px] w-full bg-dark-gray text-white text-base'>
          <svg
            className="w-4"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24"

          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
            <circle cx="8.5" cy={7} r={4} />
            <line x1={20} x2={20} y1={8} y2={14} />
            <line x1={23} x2={17} y1={11} y2={11} />
          </svg>
          Add friend
        </a>

        <p className='text-base mb-5'>Contact list</p>

        <div className='list-wrapper mb-8'>
          <p className='text-base mb-1 ml-2'>A</p>
          <div className='bg-dark-gray p-4 rounded-[10px]'>
            <a href='' className='py-3 flex gap-2 items-start'>
              <div className='relative w-9'>
                <img src={avatar} className='w-9 rounded-full' />
                <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
              </div>
              <div className='border-b border-gray-600 w-full pb-3'>
                <p className='text-sm'>Alex Hales</p>
                <p className='text-[10px] text-gray-400'>This is sample</p>
              </div>
            </a>
            <a href='' className='py-3 flex gap-2 items-start'>
              <div className='relative w-9'>
                <img src={avatar} className='w-9 rounded-full' />
                <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
              </div>
              <div className='border-b border-gray-600 w-full pb-3'>
                <p className='text-sm'>Alex Hales</p>
                <p className='text-[10px] text-gray-400'>This is sample</p>
              </div>
            </a>
            <a href='' className='py-3 flex gap-2 items-start'>
              <div className='relative w-9'>
                <img src={avatar} className='w-9 rounded-full' />
                <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
              </div>
              <div className='border-b border-gray-600 w-full pb-3'>
                <p className='text-sm'>Alex Hales</p>
                <p className='text-[10px] text-gray-400'>This is sample</p>
              </div>
            </a>
            <a href='' className='py-3 flex gap-2 items-start'>
              <div className='relative w-9'>
                <img src={avatar} className='w-9 rounded-full' />
                <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
              </div>
              <div className='border-b border-gray-600 w-full pb-3'>
                <p className='text-sm'>Alex Hales</p>
                <p className='text-[10px] text-gray-400'>This is sample</p>
              </div>
            </a>
          </div>
        </div>

        <div className='list-wrapper mb-8'>
          <p className='text-base mb-1 pl-2'>B</p>
          <div className='bg-dark-gray p-4 rounded-[10px]'>
            <a href='' className='py-3 flex gap-2 items-start'>
              <div className='relative w-9'>
                <img src={avatar} className='w-9 rounded-full' />
                <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
              </div>
              <div className='border-b border-gray-600 w-full pb-3'>
                <p className='text-sm'>Bean</p>
                <p className='text-[10px] text-gray-400'>This is sample</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
