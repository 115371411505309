import React from 'react'
import dots from "../../images/dots.png";
import plus from "../../images/plus.svg";
import { SearchField } from '../../components/SearchField/SearchField';
import { ChatHeader } from '../../components/ChatHeader/ChatHeader';

export const ChatHome = () => {

  return (
    <React.Fragment>
      <div className='main-wrapper'>
        <ChatHeader />
        <SearchField />

        <div className='text-gray-400 text-base text-center h-scale flex justify-center items-center'>
          No conversations yet
        </div>
      </div>
    </React.Fragment>
  )
}
