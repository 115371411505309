"use client";
import React from 'react';
import cross from "../../images/cross.svg";
import block from "../../images/block.svg";

export default function DeleteModal({ setShowDeleteModal }) {

  return (
    <React.Fragment>

      {/* Delete Modal Starts Here */}
      <div className='app-modal'>
        <div
          className="wallet-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[70] outline-none focus:outline-none"
        >
          <div className="relative w-full h-full">
            {/*content*/}
            <div className="mt-10 absolute md:relative bottom-0 max-[768px]:w-full mx-auto border-0 rounded-tl-[30px] rounded-tr-[30px] md:rounded-[30px] flex flex-col w-[450px] bg-dark-gray outline-none focus:outline-none">
              {/*header*/}
              <button className='close-modal absolute right-[20px] top-[12px]' onClick={() => setShowDeleteModal(false)}>
                <img src={cross} className='w-6' alt='Media' />
              </button>
              <div className='modal-content rounded-2xl px-[20px] py-3 mt-4'>
                <div className='mt-4 mb-6'>
                  <p className='text-base text-center text-white'>Delete chat</p>
                </div>
                <div className='mb-6'>
                  <button onClick={() => setShowDeleteModal(false)} className='mb-4 w-full bg-more-gray text-[#ff0000] text-center rounded-[10px] p-4'>
                    Delete
                  </button>

                </div>
                {/*body*/}

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Modal Ends Here */}
    </React.Fragment>
  )
}
