import React from 'react'
import avatar from "../../images/avatar.png";

export const ChattingPage = () => {

  return (
    <React.Fragment>
      <div className='w-full md:w-[450px] mx-auto'>
        <div className='bg-dark-gray p-2 flex items-center gap-4'>
          <a href='/addfriend'>
            <svg className='w-6' fill='#47FDCA' viewBox="0 0 96 96">
              <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z" />
            </svg>
          </a>
          <a href='/chatting' className='flex gap-2 items-center'>
            <div className='relative w-9'>
              <img src={avatar} className='w-9 min-w-9 rounded-full' />
              <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
            </div>
            <div className='w-full'>
              <p className='text-xs text-green'>Alex</p>
              <p className='text-[10px] text-gray-400'>3h7x2gf9kq</p>
            </div>
          </a>
        </div>

        <div className='px-5'>
          <p className='text-center text-base mt-2 mb-4'>Today</p>

          <div className='conversation'>
            <div className='conversation-container'>
              <div className="message sent">
                What happened last night?
                <span className="metadata">
                  <span className="time">1:36 PM</span>
                  <span className="tick">
                    <svg
                      width={16}
                      height={15}
                      x={2063}
                      y={2076}
                    >
                      <path
                        d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                        fill="#4fc3f7"
                      />
                    </svg>
                  </span>
                </span>
              </div>

              <div className="message sent">
                <div className='media-item'>
                  <svg
                    className="w-9"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1={16} x2={8} y1={13} y2={13} />
                    <line x1={16} x2={8} y1={17} y2={17} />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  <div>IMG_0475</div>
                </div>
                <span className="metadata size">
                  <span className="time items-center gap-1 font-medium">2.4 MB
                    <div className='rounded-full bg-gray-500 w-1 h-1'></div>
                    png
                  </span>
                </span>
                <span className="metadata">
                  <span className="time">1:36 PM</span>
                  <span className="tick">
                    <svg
                      width={16}
                      height={15}
                      x={2063}
                      y={2076}
                    >
                      <path
                        d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                        fill="#4fc3f7"
                      />
                    </svg>
                  </span>
                </span>
              </div>

              <div className="message received">
                I was sick.
                <span className="metadata">
                  <span className="time">1:36 PM</span>
                </span>
              </div>

            </div>
          </div>

          <div className='grid grid-cols-[24px_auto_24px] gap-3 fixed bottom-0 w-full left-0 bg-dark-gray px-4 py-3'>
            <button>
              <svg
                className='w-6'
                fill='white'
                viewBox="0 0 32 32"
              >
                <path d="M28,14H18V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2  s2-0.896,2-2V18h10c1.104,0,2-0.896,2-2S29.104,14,28,14z" />
              </svg>
            </button>
            <input type='text' className='w-full outline-0 rounded-full border border-white px-4 py-2 text-sm text-white bg-dark-gray' />
            <button>
              <svg fill='white' viewBox="0 0 32 32" className='w-6'>
                <g data-name="Layer 46" id="Layer_46">
                  <path
                    className="cls-1"
                    d="M16,27A10,10,0,0,1,6,17a1,1,0,0,1,2,0,8,8,0,0,0,16,0,1,1,0,0,1,2,0A10,10,0,0,1,16,27Z"
                  />
                  <path
                    className="cls-1"
                    d="M16,31a1,1,0,0,1-1-1V26a1,1,0,0,1,2,0v4A1,1,0,0,1,16,31Z"
                  />
                  <path
                    className="cls-1"
                    d="M16,23a6,6,0,0,1-6-6V7A6,6,0,0,1,22,7V17A6,6,0,0,1,16,23ZM16,3a4,4,0,0,0-4,4V17a4,4,0,0,0,8,0V7A4,4,0,0,0,16,3Z"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>


      </div>
    </React.Fragment>
  )
}
