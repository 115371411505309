import React from 'react'
import dots from "../../images/dots.png";
import plus from "../../images/plus.svg";

export const SearchField = () => {

  return (
    <React.Fragment>
      <div className="group fields py-5">
        <div className="relative flex items-center">
          <input
            type="text"
            placeholder='Enter User ID'
            className="relative h-8 text-xs w-full border border-gray-400 rounded-full bg-[#2C2C2E] pr-4 pl-9 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-[#2C2C2E] focus:ring-1 focus:ring-green focus:drop-shadow-lg"
          />
          <span className="absolute left-[10px] transition-all duration-200 ease-in-out group-focus-within:text-primary">
            <svg
              className='w-5'
              fill='white'
              viewBox="0 0 512 512"
            >
              <path d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3  c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2  c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2  c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z" />
            </svg>
          </span>
        </div>

      </div>


    </React.Fragment>
  )
}
