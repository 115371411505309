import React, { useRef, useEffect, useState } from 'react';
import dots from "../../images/dots.png";
import plus from "../../images/plus.svg";
import mute from "../../images/mute.svg";
import moredots from "../../images/moredots.svg";
import avatar from "../../images/avatar.png";
import { SearchField } from '../../components/SearchField/SearchField';
import MoreModal from '../MoreModal/MoreModal';

export const ChatSlider = () => {
  const [showMoreModal, setShowMoreModal] = React.useState(false);


  const chatItemRef = useRef(null);
  const slideActionsRef = useRef(null);
  const [translateX, setTranslateX] = useState(0);

  let startX = 0;
  let currentX = 0;

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    currentX = e.touches[0].clientX;
    const moveX = startX - currentX;

    // Slide left to reveal the action
    if (moveX > 0 && moveX < 100) {
      setTranslateX(-moveX);
    }
  };

  const handleTouchEnd = () => {
    if (startX - currentX > 50) {
      // Fully reveal actions if swipe is far enough
      setTranslateX(-80);
    } else {
      // Reset if swipe wasn't far enough
      setTranslateX(0);
    }
  };

  useEffect(() => {
    const chatItemElement = chatItemRef.current;

    if (chatItemElement) {
      chatItemElement.addEventListener('touchstart', handleTouchStart);
      chatItemElement.addEventListener('touchmove', handleTouchMove);
      chatItemElement.addEventListener('touchend', handleTouchEnd);

      // Cleanup event listeners on component unmount
      return () => {
        chatItemElement.removeEventListener('touchstart', handleTouchStart);
        chatItemElement.removeEventListener('touchmove', handleTouchMove);
        chatItemElement.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, []); // Empty dependency array ensures this only runs on mount

  return (
    <React.Fragment>
      {showMoreModal && (<MoreModal setShowMoreModal={setShowMoreModal} />)}
      <main>
        <div className="chat-container">
          <div ref={chatItemRef} className="chat-item" style={{ transform: `translateX(${translateX}px)`, transition: 'transform 0.3s ease' }}>
            <div className="chat-content">
              <p className="username">
                <a href='/chattingpage' className='flex gap-2 items-start'>
                  <div className='relative min-w-9 max-w-9'>
                    <img src={avatar} className='min-w-9 max-w-9 rounded-full' />
                    <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
                  </div>
                  <div className='w-full'>
                    <p className='text-sm text-green'>Alex</p>
                    <p className='text-[10px] text-gray-400'>3h7x2gf9kq</p>
                  </div>
                </a>
              </p>
              <div className='flex gap-1 flex-col items-end'>
                <a href='' className='text-[10px] text-gray-400'>9:45PM</a>
                <img src={mute} className='min-w-[18px] w-[18px] max-w-[18px] rounded-full' />
              </div>
            </div>
            <div ref={slideActionsRef} className="slide-actions" style={{ transform: `translateX(${translateX === -100 ? 0 : 100}%)`, transition: 'transform 0.3s ease' }}>
              <button onClick={() => setShowMoreModal(true)} className="more-btn">
                <img src={moredots} className='w-5' alt='Media' />
                More
              </button>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};
