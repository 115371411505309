import React from 'react'
import avatar from "../../images/avatar.png";
import InviteModal from '../../components/InviteModal/InviteModal';

export const InviteFriend = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <React.Fragment>
      {showModal && (<InviteModal setShowModal={setShowModal} />)}
      <div>
        <div className='px-4'>
          <div className='pt-4 absolute'>
            <a href='/' className='text-green cursor-pointer font-semibold'>Cancel</a>
          </div>
          <p className='font-semibold pt-4 text-base text-center'>Add Friend</p>
          <div className="group fields pt-5 pb-8">
            <div className="relative flex items-center">
              <input
                type="text"
                placeholder='3h7x2gf9kq'
                className="relative h-8 text-xs w-full border border-gray-400 rounded-full bg-[#2C2C2E] pr-4 pl-9 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-[#2C2C2E] focus:ring-1 focus:ring-green focus:drop-shadow-lg"
              />
              <span className="absolute left-[10px] transition-all duration-200 ease-in-out group-focus-within:text-primary">
                <svg
                  className='w-5'
                  fill='white'
                  viewBox="0 0 512 512"
                >
                  <path d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3  c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2  c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2  c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z" />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div className='list-wrapper mb-8'>
          <div className='bg-dark-gray px-4 py-3 flex items-center justify-between'>
            <a href='/chattingpage' className='flex gap-2 items-start'>
              <div className='relative min-w-9 max-w-9'>
                <img src={avatar} className='min-w-9 max-w-9 rounded-full' />
                <div className='absolute w-2 h-2 right-[3px] rounded-full bg-hover bottom-0'></div>
              </div>
              <div className='w-full'>
                <p className='text-sm text-green'>Alex</p>
                <p className='text-[10px] text-gray-400'>3h7x2gf9kq</p>
              </div>
            </a>

            <a onClick={() => setShowModal(true)} className='cursor-pointer text-green text-xs'>Invite</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
