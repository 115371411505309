"use client";
import React from 'react';
import logo from "../../images/logo.svg";
import mute from "../../images/mute.svg";
import cross from "../../images/cross.svg";
import block from "../../images/block.svg";
import deleteicon from "../../images/deleteicon.svg";
import copy from "../../images/copy.svg";
import avatar from "../../images/avatar.png";


export default function MuteModal({ setShowMuteModal }) {

  return (
    <React.Fragment>

      {/* Mute Modal Starts Here */}
      <div className='app-modal'>
        <div
          className="wallet-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[60] outline-none focus:outline-none"
        >
          <div className="relative w-full h-full">
            {/*content*/}
            <div className="mt-10 absolute md:relative bottom-0 max-[768px]:w-full mx-auto border-0 rounded-tl-[30px] rounded-tr-[30px] md:rounded-[30px] flex flex-col w-[450px] bg-dark-gray outline-none focus:outline-none">
              {/*header*/}
              <button className='close-modal absolute right-[20px] top-[12px]' onClick={() => setShowMuteModal(false)}>
                <img src={cross} className='w-6' alt='Media' />
              </button>
              <div className='modal-content rounded-2xl px-[20px] py-3 mt-4'>
                <div className='mt-4 mb-8'>
                  <p className='text-base text-center text-white'>Mute notification</p>
                </div>
                <div className='mb-6'>

                  <p className='rounded-[10px] px-4 py-3 bg-more-gray text-center text-base text-white mb-4'>
                    Other members will not see that muted this chat, and you will still be notified if you are mentioned.
                  </p>

                  <div className='rounded-[10px] bg-more-gray px-6 py-3'>
                    <a href='' className='text-white text-base border-b border-[#575757] block w-full pb-2'>8 hours
                    </a>
                    <a href='' className='text-white text-base border-b border-[#575757] block w-full py-2'>
                      1 week
                    </a>
                    <a href='' className='text-white text-base block w-full pt-2'>
                      Always
                    </a>
                  </div>

                </div>
                {/*body*/}

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mute Modal Ends Here */}
    </React.Fragment>
  )
}
