"use client";
import React from 'react';
import logo from "../../images/logo.svg";
import imessage from "../../images/imessage.svg";
import telegram from "../../images/telegram.svg";
import whatsapp from "../../images/whatsapp.svg";
import mail from "../../images/mail.svg";
import copy from "../../images/copy.svg";
import blackcopy from "../../images/blackcopy.svg";
import cross from "../../images/cross.svg";
import qr from "../../images/qr.svg";
import color from "../../images/color.svg";
import download from "../../images/download.svg";
import avatar from "../../images/avatar.png";


export default function QrModal({ setShowQrModal }) {
  return (
    <React.Fragment>
      {/* QR Modal Starts Here */}
      <div className='app-modal'>
        <div
          className="wallet-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-full h-full">
            {/*content*/}
            <div className="mt-10 relative md:relative bottom-0 max-[768px]:w-full mx-auto border-0 rounded-tl-[30px] rounded-tr-[30px] md:rounded-[30px] flex flex-col w-[450px] bg-dark-gray outline-none focus:outline-none">
              {/*header*/}
              <button className='close-modal absolute right-[20px] top-[12px]' onClick={() => setShowQrModal(false)}>
                <img src={cross} className='w-6' alt='Media' />
              </button>
              <div className='modal-content rounded-2xl px-[20px] py-3 mt-4'>
                <div className='mt-4 mb-16'>
                  <h2 className='text-xl text-center font-semibold'>QR Code</h2>
                </div>
                <div className='mb-6'>
                  <div className='relative rounded-[32px] bg-green px-4 py-8'>
                    <img src={qr} className='w-full' alt='Media' />
                    <div className='flex justify-center items-center flex-col gap-2'>
                      <div className='absolute -top-8 w-[60px]'>
                        <img src={avatar} className='w-[60px] rounded-full' />
                        <img src={logo} className='absolute bottom-0 right-0 w-5 rounded-full' />
                      </div>
                    </div>
                    <div className='flex gap-2 items-center justify-center -mt-4'>
                      <img src={blackcopy} className='w-4' />
                      <p className='text-base text-black font-bold'>williamwang.69</p>
                    </div>
                  </div>

                  <div className='flex justify-center items-center gap-3 my-5'>
                    <button className='rounded-lg flex flex-col justify-center items-center gap-[6px] py-3 px-6 bg-icon'>
                      <img src={download} className='w-5' alt='Media' />
                      <p className='text-xs text-white text-center'>Save</p>
                    </button>

                    <button className='rounded-lg flex flex-col justify-center items-center gap-[6px] py-3 px-6 bg-icon'>
                      <img src={color} className='w-5' alt='Media' />
                      <p className='text-xs text-white text-center'>Color</p>
                    </button>
                  </div>

                  <div className='p-3 rounded-xl border-2 border-icon flex items-center gap-3'>
                    <img src={copy} className='w-6' alt='Media' />
                    <p className='text-gray-400 text-xs break-all'>
                      https://www.figma.com/design/m5dZDduXx4bPtDKhtMC0fj/WOW-Chats?node-id=853-695&node-type=canvas&t=gG9mPOJwDLI9G1Rh-0
                    </p>
                  </div>

                  <p className='text-xs text-gray-400 my-4 text-center'>
                    Only share your QR code and link with people you trust.
                    When shared others will be able to view your username and start a chat with you.
                  </p>

                  <div className='flex justify-center mb-5 text-green'>
                    <a href=''>Reset</a>
                  </div>

                  <button className='mb-5 w-full bg-green text-black rounded-[10px] px-6 py-3 text-center font-bold'>
                    Copy
                  </button>

                </div>
                {/*body*/}

              </div>
            </div>

          </div>
        </div>
      </div>
      {/* QR Modal Ends Here */}
    </React.Fragment>
  )
}
