"use client";
import React from 'react';
import cross from "../../images/cross.svg";
import buffer from "../../images/buffer.svg";

export default function BlockingModal({ setShowBlockingModal }) {

  return (
    <React.Fragment>

      {/* Blocking Modal Starts Here */}
      <div className='app-modal'>
        <div
          className="wallet-wrapper bg-[#0000008c] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[80] outline-none focus:outline-none"
        >
          <div className="relative w-full h-full">
            {/*content*/}
            <div className="absolute md:relative max-[768px]:w-1/2 left-1/2 top-1/2 -translate-x-2/4 -translate-y-2/4 border-0 rounded-[22px] flex flex-col w-[450px] bg-dark-gray outline-none focus:outline-none">
              <div className='modal-content rounded-2xl px-[20px] py-3 mt-4'>
                <div className='mt-4 mb-6'>
                  <p className='text-xl text-center text-white'>Blocking...</p>
                </div>
                <div className='flex justify-center mb-6'>
                  <img src={buffer} className='w-[30px] rotate-animation' />
                </div>
                {/*body*/}

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blocking Modal Ends Here */}
    </React.Fragment>
  )
}
