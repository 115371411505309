import React from 'react'
import logo from "../images/logo.svg";

export const Home = () => {

  return (
    <React.Fragment>
      <div className='main-wrapper'>
        <div className='pt-4'>
          <a to='/' className='text-green font-semibold'>Cancel</a>
        </div>
        <h1 className='text-2xl font-bold text-center pt-12'>Welcome to </h1>
        <div className='flex justify-center flex-col items-center py-24'>
          <img className="w-28 rounded-[5px]" src={logo} alt="Media" />
          <p className='text-center font-bold text-base uppercase pt-1'>WOW Chat</p>
        </div>
        <p className='text-xs font-medium text-center'>
          Click below to get started!
        </p>
        <div className='pt-4 flex justify-center'>
          <svg className='w-8' fill='white' viewBox="0 0 32 32">
            <g data-name="Layer 2" id="Layer_2">
              <path
                className="cls-1"
                d="M16.47,16.88,26.34,7a1,1,0,0,0-1.41-1.41l-9.06,9.06-8.8-8.8a1,1,0,0,0-1.41,0h0a1,1,0,0,0,0,1.42l9.61,9.61A.85.85,0,0,0,16.47,16.88Z"
              />
              <path
                className="cls-1"
                d="M16.47,26.46l9.87-9.88a1,1,0,0,0-1.41-1.41l-9.06,9.06-8.8-8.8a1,1,0,0,0-1.41,0h0a1,1,0,0,0,0,1.41l9.61,9.62A.85.85,0,0,0,16.47,26.46Z"
              />
            </g>
          </svg>
        </div>
        <a href='/username' className='text-center block min-h-11 mt-7 mb-3 p-3 rounded-lg w-full bg-btn hover:bg-hover text-white text-base font-medium'>
          Join Wow Chat
        </a>
      </div>
    </React.Fragment>
  )
}
