import React from 'react'
import logo from "../../images/logo.svg";

export const Username = () => {

  return (
    <React.Fragment>
      <div className='main-wrapper'>
        <div className='pt-4 absolute'>
          <a href='/' className='text-green cursor-pointer font-semibold'>Cancel</a>
        </div>
        <p className='font-semibold pt-4 text-base text-center'>Set Username</p>

        <div className='flex flex-col items-center pt-8 justify-center gap-2'>
          <div className='rounded-full w-[105px] h-[105px] cursor-pointer bg-[#1C1C1E] flex items-center justify-center'>
            <svg
              className='w-6'
              fill='white'
              viewBox="0 0 24 24"
            >
              <g>
                <path d="M19,20H5c-1.654,0-3-1.346-3-3V9c0-1.654,1.346-3,3-3h1.586l1-1C8.165,4.421,9.182,4,10,4h4c0.818,0,1.835,0.421,2.414,1   l1,1H19c1.654,0,3,1.346,3,3v8C22,18.654,20.654,20,19,20z M5,8C4.448,8,4,8.448,4,9v8c0,0.552,0.448,1,1,1h14c0.552,0,1-0.448,1-1   V9c0-0.552-0.448-1-1-1h-2c-0.266,0-0.52-0.105-0.707-0.293L15,6.414C14.799,6.213,14.285,6,14,6h-4C9.715,6,9.201,6.213,9,6.414   L7.707,7.707C7.52,7.895,7.266,8,7,8H5z" />
              </g>
              <g>
                <path d="M12,10c1.379,0,2.5,1.121,2.5,2.5S13.379,15,12,15s-2.5-1.121-2.5-2.5S10.621,10,12,10 M12,9c-1.934,0-3.5,1.566-3.5,3.5   c0,1.932,1.566,3.5,3.5,3.5s3.5-1.568,3.5-3.5C15.5,10.566,13.934,9,12,9z" />
              </g>
              <g>
                <path d="M18,8.699c-0.719,0-1.3,0.582-1.3,1.301s0.581,1.299,1.3,1.299s1.3-0.58,1.3-1.299S18.719,8.699,18,8.699z" />
              </g>
            </svg>
          </div>
          <p className='text-xs font-semibold text-center'>Add Profile picture</p>
        </div>

        <p className='text-sm text-center pt-10 pb-6'>
          You can choose a username to help others more easily find and reach you on wow
        </p>

        <div className="group fields mb-5">
          <label
            className="inline-block pb-1 w-full text-xs font-medium text-balck transition-all duration-200 ease-in-out group-focus-within:text-primary"
          >
            Username
          </label>
          <div className="relative flex items-center">
            <input
              type="text"
              placeholder='Username'
              className="relative h-10 text-xs w-full rounded-md bg-[#2C2C2E] px-4 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-[#2C2C2E] focus:ring-2 focus:ring-green focus:drop-shadow-lg"
            />
          </div>
          <p className='text-right text-xs text-[#64EAAA] pt-1'>username is available</p>
        </div>

        <div className="group fields mb-5">
          <label
            className="inline-block pb-1 w-full text-xs font-medium text-balck transition-all duration-200 ease-in-out group-focus-within:text-primary"
          >
            User ID
          </label>
          <div className="relative flex items-center">
            <input
              type="text"
              placeholder='User ID'
              className="relative h-10 text-xs w-full rounded-md bg-[#2C2C2E] px-4 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-[#2C2C2E] focus:ring-2 focus:ring-green focus:drop-shadow-lg"
            />
          </div>
          {/* <p className='text-right text-xs text-[#64EAAA] pt-1'>user id is available</p> */}
        </div>

        <a href='/chathome' className='text-center block min-h-11 mt-9 mb-3 p-3 rounded-lg w-full bg-btn hover:bg-hover text-white text-base font-medium'>
          Create
        </a>
      </div>
    </React.Fragment>
  )
}
